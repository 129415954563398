<template>
  <!-- <ScrollContainer :initial-event="true"> -->
  <div class="main">
    <!-- The 100 섹션 -->
    <!-- <TheHundred /> -->
    <!-- Video 섹션 -->
    <!-- <StickyTest /> -->
    <!-- <Video :key="breakpoint" /> -->
    <OurMission />
    <!-- <IntensiveRecuruit :key="breakpoint" />
    <AboutCookapps />
    <EmployeeBanner />
    <GameBanner />
    <NewStory /> -->
    <!-- Subscribe Banner 섹션 -->
    <!-- <SubscribeBanner /> -->
    <!-- Intro 섹션 -->
    <!-- <Intro /> -->
    <!-- Job 섹션 -->
    <!-- <Job /> -->
    <!-- Game 섹션 -->
    <!-- <Game /> -->
    <!-- Work 섹션 -->
    <!-- <Work /> -->
    <!-- Story 섹션 -->
    <!-- <Story /> -->
    <!-- Benefit 섹션 -->
    <!-- <Benefit /> -->
    <!-- Hiring 섹션 -->
    <!-- <Hiring /> -->
    <!-- </ScrollContainer> -->
    <!-- <main-modal /> -->
    <!-- <JobSection /> -->
    <!-- <WantedLiveModal /> -->
  </div>
</template>

<script>
// import Video from './components/Video.vue';
// import IntensiveRecuruit from './components/IntensiveRecruit.vue';
// import Intro from './components/Intro.vue';
// import Game from './components/Game.vue';
// import Work from './components/Work.vue';
// import Story from './components/Story.vue';
// import Benefit from './components/Benefit.vue';
// import Job from './components/Job.vue';
// import Hiring from './components/Hiring.vue';
// import StickyTest from './components/stickyTest.vue';
// import EmployeeBanner from './components/EmployeeBanner.vue';
// import AboutCookapps from './components/AboutCookapps.vue';
// import JobSection from './components/jobSection.vue';
// import GameBanner from './components/GameBanner.vue';
// import NewStory from './components/NewStory.vue';
// import TheHundred from './components/TheHundred.vue';
// import SubscribeBanner from '../../layout/components/SubscribeBanner.vue';
// import MainModal from '../../layout/components/MainModal.vue';
// import WantedLiveModal from '@/layout/components/WantedLiveModal.vue';
import OurMission from './components/OurMission.vue';

export default {
  name: 'MainSection',
  components: {
    // Video,
    // IntensiveRecuruit,
    // Intro,
    // Game,
    // Work,
    // Story,
    // Benefit,
    // Job,
    // Hiring,
    // StickyTest,
    // EmployeeBanner,
    // AboutCookapps,
    // JobSection,
    // GameBanner,
    // NewStory,
    // WantedLiveModal,
    OurMission
  },
  computed: {
    time() {
      return new Date(2022, 3, 26) - new Date();
    },
    breakpoint() {
      return this.$screen.breakpoint;
    }
  },
  created(){
    window.fbq('track', 'cookapps_main_view');
  }
};
</script>

<style lang="scss" scoped>

</style>
