import request from '@/utils/request';

export const getAboutUsYoutube = (params) => request({
  url: '/web/cookapps-story/youtube/about-us',
  method: 'get',
  params
});

export const getCultureYoutube = (params) => request({
  url: '/web/cookapps-story/youtube/culture',
  method: 'get',
  params
});

export const getJobYoutube = (params) => request({
  url: '/web/cookapps-story/youtube/jobs',
  method: 'get',
  params
});

export const fetchArticleList = () => request({
  url: '/web/article/list',
  method: 'get',
});

export const getArticle = (id) => request({
  url: `/web/article/get/${id}`,
  method: 'get',
});
